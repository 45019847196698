<template>
  <div class="allsymbol">
    <van-sticky :z-index="9999">
      <div class="allsymbol-head">
        <NavBar>
          <van-icon
            slot="left"
            color="#fff"
            name="arrow-left"
            @click="$router.push('/quotes/addstock')"
          />
          <div class="allsymbol-title" slot="title" style="color: #fff">
            {{ $t("Mt4ApiTrade.addSymbol") }}
          </div>
          <template #right>
            <van-icon
              name="search"
              color="#fff"
              size="18"
              @click="search"
              @input="onInput"
            />
          </template>
        </NavBar>
      </div>
      <van-search
        v-model="value"
        show-action
        :placeholder="$t('Mt4ApiTrade.searchSymbol')"
        @search="onSearch"
        shape="round"
        v-if="searchShow"
      >
        <template #action>
          <div @click="abolish">{{ $t("cancel") }}</div>
        </template>
      </van-search>
    </van-sticky>
    <van-list v-if="!records.length">
      <van-cell
        v-for="(item, index) in list"
        :key="index"
        :title="item.symbol"
        style="font-weight: 750; color: #333"
      >
        <template #right-icon>
          <van-image
            width="100"
            height="100"
            :src="addImg"
            @click="addStock(item, index)"
          />
        </template>
      </van-cell>
    </van-list>
    <van-list v-else>
      <van-cell
        v-for="(item, index) in records"
        :key="index"
        :title="item.symbol"
        style="font-weight: 750; color: #333"
      >
        <template #right-icon>
          <van-image
            width="100"
            height="100"
            :src="addImg"
            @click="addStock(item, index)"
          />
        </template>
      </van-cell>
    </van-list>
  </div>
</template>
<script>
import { GetSymbols } from "@/api/mt4Api";
    import { Notify, Dialog } from 'vant';
import NavBar from "@/components/mt4/NavBar";
export default {
  components: { NavBar },
  data() {
    return {
      addImg: require("@/assets/image/mt4tabbar/add.png"),
      value: "",
      searchShow: false,
      list: [],
      iconShow: true,
      flag: false,
      currect: 0,
      existSymbol: [],
      addArr: [],
      records: [],
    };
  },
  computed: {},
  filters: {},
  created() {
    const mold = this.$route.query.symbol;
    this.list = JSON.parse(localStorage.getItem("odd")).filter(
      (item) => item.group_name == mold
    );
  },
  mounted() {
    this.$store.commit("toggleTabbarShow", true);
    this.$store.commit("toggleTabbarCurrentShow", "Mt4");
    const exist = localStorage.getItem("exits");
    this.addArr.push(...JSON.parse(exist));
  },
  methods: {
    abolish() {
      this.searchShow = false;
    },
    search() {
      this.searchShow = true;
    },
    onInput(val) {
      this.records = [];
      this.list.forEach((element) => {
        if (element.symbol.indexOf(val.toUpperCase()) != -1) {
          //console.log(element.ticket);
          this.records.push(element);
        }
      });
    },
    onSearch(val) {
      this.records = [];
      this.list.forEach((element) => {
        if (element.symbol.indexOf(val.toUpperCase()) != -1) {
          //console.log(element.ticket);
          this.records.push(element);
        } else {
            Notify({ type: 'danger', message: this.$t("nomore") });
         
        }
      });
    },
    back() {
      this.$router.replace("/quotes/addstock");
    },
    addStock(data, index) {
      this.addArr.push(data);
      let odd = JSON.parse(localStorage.getItem("odd"));
      odd.splice(
        odd.findIndex((item) => item.symbol == data.symbol),
        1
      );
      localStorage.setItem("odd", JSON.stringify(odd));
      localStorage.setItem("addArr", JSON.stringify(this.addArr));
      this.list.splice(index, 1);
        Notify({ type: 'success', message: this.$t("success") });
    },
  },
};
</script>

<style>
.allsymbol .van-cell .van-icon-plus {
  color: #13b5b1;
  margin-right: 0.2rem;
}

.van-cell .van-icon-fire {
  color: red;
}

.isShow {
  display: none;
}

.allsymbol-head .van-nav-bar {
  background-color: #13b5b1;
}

.allsymbol .van-image {
  width: 40px !important;
  height: 40px !important;
}

.allsymbol .van-card {
  margin-top: 0;
  padding: 1px;
}

.allsymbol .van-card__thumb {
  width: 1px;
  height: 1px;
  margin-right: 0;
}

.allsymbol .van-card__content {
  min-height: 0;
  height: 0;
}

.allsymbol .van-cell__title {
  display: flex;
  align-items: center;
}

.allsymbol .van-cell {
  padding: 6px 16px;
}
</style>